document.addEventListener('DOMContentLoaded', async () => {
  // Service create/edit form and select media dialogs
  const { ServiceFormDialog } = await import('~/services/service-form-dialog');
  ServiceFormDialog.init();

  const { ServiceSelectMediaDialog } = await import('~/services/service-select-media-dialog');
  ServiceSelectMediaDialog.init();

  // Missing payout provider warning dialog
  const { ConnectPayoutDialog } = await import('~/marketplace/connect-payout-dialog');
  ConnectPayoutDialog.init();
});
